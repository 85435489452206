import blogStyle from '../css/Blog.css';

function Blog() {
  return(
    <section id="blog">
      <h2> Blogs & Resources</h2>
      <div className="brown-icon-separator"></div>
      <p> Coming Soon! </p>
    </section>
  );
}

export default Blog;
